import React, { useContext, useState } from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Form from "../../components/Form";
import * as Buttons from "../../components/Buttons";

import { Translater, config } from "../../config";
import { useHttp } from "../../hooks";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { SwitchOnlinePayment } from "./components/SwitchOnlinePayment/SwitchOnlinePayment";

const IndexPage: React.FC = () => {
  const { actions: authActions } = useContext(Contexts.AuthContext);
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);
  const { request } = useHttp();

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);

  const updateProducts = async () => {
    try {
      await request(
        `${config.API}/master-system/syncNomenclature`,
        "POST",
        null,
        {
          Authorization: `Bearer ${token as string}`,
        }
      );
    } catch (e) {
      console.log("E", e);
    } finally {
      toogleIsAlertOpen(true);
    }
  };

  return (
    <Page.Wrapper title={Translater.SettingsPage.title[language.slug]}>
      <Page.Header title={Translater.SettingsPage.title[language.slug]} />

      <Form.LabelField
        label={Translater.SettingsPage.langSwitchLabel[language.slug]}
        fontSize="small"
        fontWeight="bold"
      >
        <Form.LanguageToggler />
      </Form.LabelField>

      <Buttons.Container
        logoutHandler={authActions.LOGOUT}
        updateProductsHandler={updateProducts}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="success">
          {Translater.SettingsPage.alert[language.slug]}
        </Alert>
      </Snackbar>
      <SwitchOnlinePayment />
    </Page.Wrapper>
  );
};

export default IndexPage;
