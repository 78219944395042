import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import * as Contexts from "../../../../contexts";
import { config, Translater } from "../../../../config";
import { useHttp } from "../../../../hooks";
import { Button, Switch } from "@material-ui/core";
import { IRestaurant } from "../../../../types/items";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";

export const SwitchOnlinePayment = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { request } = useHttp();
  const [restaurant, setRestaurant] = useState<IRestaurant | null>(null);
  const { language } = useContext(Contexts.LanguageContext);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });
  const reataurans = async () =>
    await request(`${config.serverURL}admin/api/restaurants`, "GET", null, {
      Authorization: `Bearer ${token as string}`,
    });
  useEffect(() => {
    reataurans().then((res) => setRestaurant(res[0]));
  }, []);

  const createFormData = (restaurantData: IRestaurant) => {
    const data = new FormData();

    data.append("title", restaurantData.title);
    data.append(
      "city",
      typeof restaurantData.city === "string"
        ? restaurantData.city
        : restaurantData.city._id
    );
    data.append("headerColor", restaurantData.headerColor);

    data.append("contacts", JSON.stringify(restaurantData.contacts));

    data.append(
      "workingTime",
      JSON.stringify({
        start: restaurantData.workingTime.start,
        end: restaurantData.workingTime.end,
      })
    );

    if (restaurantData.photo) data.append("photo", restaurantData.photo);
    if (restaurantData.headerPhoto)
      data.append("headerPhoto", restaurantData.headerPhoto);

    data.append(
      "onlinePayment",
      JSON.stringify(restaurantData.onlinePayment || false)
    );

    if (restaurantData.booking?.description) {
      data.append("bookingDescription", restaurantData.booking.description);
    }
    if (restaurantData.booking?.photos) {
      data.append(
        "bookingPhotos",
        JSON.stringify(restaurantData.booking.photos)
      );
    }

    if (restaurantData.description) {
      data.append("description", restaurantData.description);
    }

    if (restaurantData.price) {
      data.append("price", restaurantData.price);
    }

    if (restaurantData.position !== undefined) {
      data.append("position", String(restaurantData.position));
    }

    data.append("percents", String(restaurantData.percents));

    if (restaurantData.role) {
      data.append("role", String(restaurantData.role));
    }

    return data;
  };

  const updateRestaurant = async () => {
    try {
      await request(
        `${config.serverURL}admin/api/restaurants/${restaurant?._id}`,
        "POST",
        restaurant && createFormData(restaurant),
        {
          Authorization: `Bearer ${token as string}`,
        }
      );
      setSnackbar({
        open: true,
        message: Translater.OnlinePayment.toastSuccess[language.slug],
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating restaurant:", error);
      setSnackbar({
        open: true,
        message: Translater.OnlinePayment.toastError[language.slug],
        severity: "error",
      });
    }
  };

  return (
    <div className={styles.container}>
      <Switch
        checked={!!restaurant?.onlinePayment}
        color="primary"
        onChange={() =>
          setRestaurant(
            (prev) => prev && { ...prev, onlinePayment: !prev.onlinePayment }
          )
        }
      />
      <p> {Translater.OnlinePayment.title[language.slug]} </p>
      <Button variant="outlined" color="primary" onClick={updateRestaurant}>
        {Translater.OnlinePayment.button[language.slug]}
      </Button>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
